// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crowd-index-js": () => import("./../../../src/pages/crowd/index.js" /* webpackChunkName: "component---src-pages-crowd-index-js" */),
  "component---src-pages-how-to-index-js": () => import("./../../../src/pages/how-to/index.js" /* webpackChunkName: "component---src-pages-how-to-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metric-design-guidelines-js": () => import("./../../../src/pages/metric-design-guidelines.js" /* webpackChunkName: "component---src-pages-metric-design-guidelines-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-workshops-equity-dataviz-js": () => import("./../../../src/pages/workshops/equity-dataviz.js" /* webpackChunkName: "component---src-pages-workshops-equity-dataviz-js" */),
  "component---src-pages-workshops-index-js": () => import("./../../../src/pages/workshops/index.js" /* webpackChunkName: "component---src-pages-workshops-index-js" */),
  "component---src-pages-workshops-visualizing-people-js": () => import("./../../../src/pages/workshops/visualizing-people.js" /* webpackChunkName: "component---src-pages-workshops-visualizing-people-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-work-page-js": () => import("./../../../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

